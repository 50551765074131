/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query All($first: Int!, $after: String) {\n    blobs(first: $first, after: $after) {\n      totalCount\n      edges {\n        cursor\n        node {\n          __typename\n          id\n\n          name\n\n          asImage {\n            __typename\n            id\n\n            url\n            dimensions {\n              width\n              height\n            }\n          }\n\n          asVideo {\n            poster {\n              url\n              dimensions {\n                width\n                height\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.AllDocument,
    "\n  fragment BlobEditPage on Blob {\n    id\n\n    url\n    contentType\n    size\n\n    createdAt\n    location {\n      label\n    }\n\n    description\n\n    asImage {\n      url\n\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      poster {\n        url\n\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n": types.BlobEditPageFragmentDoc,
    "\n  query BlobEditPage($name: String!) {\n    blob(name: $name) {\n      ...BlobEditPage\n    }\n  }\n": types.BlobEditPageDocument,
    "\n  mutation WriteAttributes($id: ID!, $input: AttributesInput) {\n    writeAttributes(id: $id, input: $input) {\n      id\n      ... on Blob {\n        createdAt\n        location {\n          label\n        }\n        description\n      }\n    }\n  }\n": types.WriteAttributesDocument,
    "\n  query BlobPage($name: String!) {\n    blob(name: $name) {\n      ...BlobPage\n    }\n  }\n": types.BlobPageDocument,
    "\n  fragment CollectionPage on Collection {\n    id\n    name\n    slug\n\n    title\n\n    blobs {\n      totalCount\n\n      edges {\n        node {\n          id\n          name\n\n          asImage {\n            url\n            dimensions {\n              width\n              height\n            }\n          }\n        }\n      }\n    }\n  }\n": types.CollectionPageFragmentDoc,
    "\n  query CollectionPage($name: String!) {\n    collection(name: $name) {\n      ...CollectionPage\n    }\n  }\n": types.CollectionPageDocument,
    "\n  query HomePage($first: Int!, $after: String) {\n    blobs(first: $first, after: $after, orderBy: { field: CREATED_AT, direction: DESC }) {\n      __typename # cachekey\n      pageInfo {\n        __typename # cachekey\n        endCursor\n        hasNextPage\n      }\n\n      edges {\n        __typename # cachekey\n        cursor\n        node {\n          id\n          ...Brick\n        }\n      }\n    }\n  }\n": types.HomePageDocument,
    "\n  fragment BlobPage on Blob {\n    id\n\n    contentType\n    size\n\n    createdAt\n    location {\n      label\n    }\n\n    description\n\n    asImage {\n      url\n\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      poster {\n        url\n\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n": types.BlobPageFragmentDoc,
    "\n  fragment Brick on Blob {\n    id\n\n    name\n\n    asImage {\n      id\n\n      url\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      id\n\n      poster {\n        id\n\n        url\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n": types.BrickFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query All($first: Int!, $after: String) {\n    blobs(first: $first, after: $after) {\n      totalCount\n      edges {\n        cursor\n        node {\n          __typename\n          id\n\n          name\n\n          asImage {\n            __typename\n            id\n\n            url\n            dimensions {\n              width\n              height\n            }\n          }\n\n          asVideo {\n            poster {\n              url\n              dimensions {\n                width\n                height\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query All($first: Int!, $after: String) {\n    blobs(first: $first, after: $after) {\n      totalCount\n      edges {\n        cursor\n        node {\n          __typename\n          id\n\n          name\n\n          asImage {\n            __typename\n            id\n\n            url\n            dimensions {\n              width\n              height\n            }\n          }\n\n          asVideo {\n            poster {\n              url\n              dimensions {\n                width\n                height\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlobEditPage on Blob {\n    id\n\n    url\n    contentType\n    size\n\n    createdAt\n    location {\n      label\n    }\n\n    description\n\n    asImage {\n      url\n\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      poster {\n        url\n\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BlobEditPage on Blob {\n    id\n\n    url\n    contentType\n    size\n\n    createdAt\n    location {\n      label\n    }\n\n    description\n\n    asImage {\n      url\n\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      poster {\n        url\n\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BlobEditPage($name: String!) {\n    blob(name: $name) {\n      ...BlobEditPage\n    }\n  }\n"): (typeof documents)["\n  query BlobEditPage($name: String!) {\n    blob(name: $name) {\n      ...BlobEditPage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation WriteAttributes($id: ID!, $input: AttributesInput) {\n    writeAttributes(id: $id, input: $input) {\n      id\n      ... on Blob {\n        createdAt\n        location {\n          label\n        }\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation WriteAttributes($id: ID!, $input: AttributesInput) {\n    writeAttributes(id: $id, input: $input) {\n      id\n      ... on Blob {\n        createdAt\n        location {\n          label\n        }\n        description\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BlobPage($name: String!) {\n    blob(name: $name) {\n      ...BlobPage\n    }\n  }\n"): (typeof documents)["\n  query BlobPage($name: String!) {\n    blob(name: $name) {\n      ...BlobPage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CollectionPage on Collection {\n    id\n    name\n    slug\n\n    title\n\n    blobs {\n      totalCount\n\n      edges {\n        node {\n          id\n          name\n\n          asImage {\n            url\n            dimensions {\n              width\n              height\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CollectionPage on Collection {\n    id\n    name\n    slug\n\n    title\n\n    blobs {\n      totalCount\n\n      edges {\n        node {\n          id\n          name\n\n          asImage {\n            url\n            dimensions {\n              width\n              height\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CollectionPage($name: String!) {\n    collection(name: $name) {\n      ...CollectionPage\n    }\n  }\n"): (typeof documents)["\n  query CollectionPage($name: String!) {\n    collection(name: $name) {\n      ...CollectionPage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HomePage($first: Int!, $after: String) {\n    blobs(first: $first, after: $after, orderBy: { field: CREATED_AT, direction: DESC }) {\n      __typename # cachekey\n      pageInfo {\n        __typename # cachekey\n        endCursor\n        hasNextPage\n      }\n\n      edges {\n        __typename # cachekey\n        cursor\n        node {\n          id\n          ...Brick\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query HomePage($first: Int!, $after: String) {\n    blobs(first: $first, after: $after, orderBy: { field: CREATED_AT, direction: DESC }) {\n      __typename # cachekey\n      pageInfo {\n        __typename # cachekey\n        endCursor\n        hasNextPage\n      }\n\n      edges {\n        __typename # cachekey\n        cursor\n        node {\n          id\n          ...Brick\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlobPage on Blob {\n    id\n\n    contentType\n    size\n\n    createdAt\n    location {\n      label\n    }\n\n    description\n\n    asImage {\n      url\n\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      poster {\n        url\n\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BlobPage on Blob {\n    id\n\n    contentType\n    size\n\n    createdAt\n    location {\n      label\n    }\n\n    description\n\n    asImage {\n      url\n\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      poster {\n        url\n\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Brick on Blob {\n    id\n\n    name\n\n    asImage {\n      id\n\n      url\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      id\n\n      poster {\n        id\n\n        url\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Brick on Blob {\n    id\n\n    name\n\n    asImage {\n      id\n\n      url\n      dimensions {\n        width\n        height\n      }\n    }\n\n    asVideo {\n      id\n\n      poster {\n        id\n\n        url\n        dimensions {\n          width\n          height\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;